import ArkansasState from "./Images/arkansas.png";
import IllinoisState from "./Images/illinois.png";
import MissouriState from "./Images/missouri.png";
import TennesseeState from "./Images/tennessee.png";

export const sidebarConfig = {
	arkansas: {
		header: "Arkansas Nutrient Reduction Strategy",
		dataAndResources: [
			{
				name: "Arkansas Nutrient Reduction Strategy",
				fileType: "web",
				url: "https://www.agriculture.arkansas.gov/natural-resources/divisions/water-management/arkansas-nutrient-reduction-strategy/",
			},
			{
				name: "Arkansas Nutrient Reduction Viewer",
				fileType: "map",
				url: "https://anrcdoc.aad.arkansas.gov/arcgisportal/apps/dashboards/92c5bf3078244aa6b1b2022236c5aae9",
			},
			{
				name: "ANRS Fact",
				fileType: "pdf",
				url: "https://agriculture.arkansas.gov/wp-content/uploads/2022/05/ANRS-Fact-Sheet-Draft-for-State-Review-Feb-2.pdf",
			},
			{
				name: "ANRS Story Map",
				fileType: "map",
				url: "https://anrcdoc.aad.arkansas.gov/arcgisportal/apps/storymaps/stories/433a65c303e146df83eec5e5fe7ab9ad",
			},
			{
				name: "Great Lakes to Gulf - Arkansas Water Quality Data Portal",
				image: ArkansasState,
				url: "https://arkansas.greatlakestogulf.org/#/",
			},
		],
	},
	illinois: {
		header: "Illinois Nutrient Loss Reduction Strategy",
		dataAndResources: [
			{
				name: "Illinois Nutrient Loss Reduction Strategy",
				fileType: "web",
				url: "https://epa.illinois.gov/topics/water-quality/watershed-management/excess-nutrients/nutrient-loss-reduction-strategy.html",
			},
			{
				name: "Illinois Nutrient Trends",
				fileType: "map",
				url: "https://greatlakestogulf.org/nutrient-trends",
			},
			{
				name: "Illinois Nutrient Loss Reduction Strategy Priority Watershed Map",
				fileType: "map",
				url: "https://epa.illinois.gov/content/dam/soi/en/web/epa/documents/water-quality/watershed-management/nlrs/nlrswatersheds.pdf",
			},
			{
				name: "Illinois Nutrient Research and Education Council",
				fileType: "web",
				url: "https://www.illinoisnrec.org/",
			},
			{
				name: "Illinois Extension Water Quality Resources",
				fileType: "web",
				url: "https://extension.illinois.edu/nlr/",
			},
			{
				name: "Illinois Sustainable Ag Partnership Story Map",
				fileType: "map",
				url: "https://ilsustainableag.org/learn/conservation-story-map/?farmtype%5B%5D=demosite&farmtype%5B%5D=research&farmtype%5B%5D=service&farmtype%5B%5D=specialist&maplayers%5B%5D=Topographic",
			},
			{
				name: "Great Lakes to Gulf - Illinois Water Quality Data Portal",
				image: IllinoisState,
				url: "https://illinois.greatlakestogulf.org/",
			},
		],
	},
	indiana: {
		header: "Indiana Nutrient Reduction Strategy",
		dataAndResources: [
			{
				name: "Indiana State Nutrient Reduction Strategy",
				fileType: "web",
				url: "https://www.in.gov/isda/divisions/soil-conservation/indiana-state-nutrient-reduction-strategy/",
			},
			{
				name: "Water Quality Trends in Indiana Streams Story Map",
				fileType: "web",
				url: "https://storymaps.arcgis.com/stories/977fe35741c34a2b860b5702c797e020",
			},
			{
				name: "Trends of Sediment and Nutrient Loads in Indiana Watersheds",
				fileType: "dashboard",
				url: "https://gisdata.in.gov/portal/apps/experiencebuilder/experience/?id=a7a5365d78444f79bcddc1bbbf443d1a&page=Sites",
			},
		],
	},
	iowa: {
		header: "Iowa Nutrient Reduction Strategy",
		dataAndResources: [
			{
				name: "Iowa Nutrient Reduction Strategy",
				fileType: "web",
				url: "https://www.nutrientstrategy.iastate.edu/documents",
			},
			{
				name: "Iowa Nutrient Reduction Strategy Data Dashboards",
				fileType: "dashboard",
				url: "https://www.arcgis.com/apps/dashboards/52865e429fc3462782a77041d6b9065d",
			},
			{
				name: "Iowa Department of Natural Resources Ambient Stream Monitoring",
				fileType: "web",
				url: "https://programs.iowadnr.gov/aquia/",
			},
			{
				name: "Iowa Nutrient Research Center",
				fileType: "web",
				url: "https://www.cals.iastate.edu/inrc/",
			},
		],
	},
	kentucky: {
		header: "Kentucky Nutrient Reduction Strategy",
		dataAndResources: [
			{
				name: "Kentucky Nutrient Reduction Strategy",
				fileType: "web",
				url: "https://eec.ky.gov/Environmental-Protection/Water/Protection/Pages/Nutrient-Reduction-Strategy.aspx",
			},
			{
				name: "Nutrient Loads and Yields in Kentucky Study",
				fileType: "dashboard",
				url: "https://kygis.maps.arcgis.com/apps/MapSeries/index.html?appid=401eaf6ea6e24ffa82985d122cf1bbb0",
			},
			{
				name: "Flow-Normalized Nutrient Loads in Kentucky Report",
				fileType: "pdf",
				url: "https://eec.ky.gov/Environmental-Protection/Water/Protection/Documents/2024_WRTDS_Loads_Study.pdf",
			},
			{
				name: "Kentucky Nutrient Strategy Update Story Map",
				fileType: "web",
				url: "https://storymaps.arcgis.com/stories/ad5b60986f04440399964378c81b1da9",
			},
		],
	},
	louisiana: {
		header: "Louisiana Nutrient Reduction Strategy",
		dataAndResources: [
			{
				name: "Louisiana Nutrient Reduction and Management Strategy",
				fileType: "web",
				url: "https://www.deq.louisiana.gov/page/nutrient-management-strategy",
			},
			{
				name: "Nitrogen and Phosphorus Trends of Long-Term Ambient Water Quality Monitoring Sites in Louisiana",
				fileType: "pdf",
				url: "https://www.deq.louisiana.gov/assets/docs/Water/Nutrient_Management_Strategy/Nitrogen_and_Phosphorus_Long-term_Trends_2021r.pdf",
			},
			{
				name: "Statewide Water Quality Monitoring Network",
				fileType: "dashboard",
				url: "https://waterdata.deq.louisiana.gov/Projects/WQ1958001",
			},
		],
	},
	minnesota: {
		header: "Minnesota Nutrient Reduction Strategy",
		dataAndResources: [
			{
				name: "Minnesota Nutrient Reduction Strategy",
				fileType: "web",
				url: "https://www.pca.state.mn.us/air-water-land-climate/reducing-nutrients-in-waters",
			},
			{
				name: "Long term stream trends in Minnesota",
				fileType: "dashboard",
				url: "https://data.pca.state.mn.us/views/Long-termstreamtrends/Pollutantconcentrations",
			},
			{
				name: "New Acres of Best Management Strategies",
				fileType: "dashboard",
				url: "https://data.pca.state.mn.us/views/Statewidebestmanagementpracticeadoptionsummary/MinnesotaNutrientReductionStrategyBMPSummary",
			},
			{
				name: "Watershed Pollutant Load Reduction Calculator",
				fileType: "dashboard",
				url: "https://data.pca.state.mn.us/views/Watershedpollutantloadreductioncalculator/WatershedPollutantLoadReductionCalculator",
			},
			{
				name: "Daily and Annual Nutrient Measurements at MPCA-monitored Site",
				fileType: "dashboard",
				url: "https://data.pca.state.mn.us/views/WatershedPollutantLoadMonitoringNetworkwatermonitoringdata/ProgramOverview?%3Aembed=y&%3AisGuestRedirectFromVizportal=y",
			},
			{
				name: "Wastewater Effluent Flow and Nutrients",
				fileType: "dashboard",
				url: "https://experience.arcgis.com/experience/21f2c024b1a94cbe8fac987580da074e#widget_4=active_datasource_id:dataSource_3,center:-10382690.857261699%2C5790258.553053291%2C102100,scale:4622324.434309,rotation:0,viewpoint:%7B%22rotation%22%3A0%2C%22scale%22%3A4622324.434309%2C%22targetGeometry%22%3A%7B%22spatialReference%22%3A%7B%22latestWkid%22%3A3857%2C%22wkid%22%3A102100%7D%2C%22x%22%3A-10382690.857261699%2C%22y%22%3A5790258.553053291%7D%7D",
			},
		],
	},
	mississippi: {
		header: "Mississippi Nutrient Reduction Strategy",
		dataAndResources: [
			{
				name: "Mississippi Nutrient Reduction Strategy",
				fileType: "web",
				url: "https://www.mdeq.ms.gov/water/surface-water/nonpoint-source-pollution-program/nutrient-reduction-in-mississippi/",
			},
		],
	},
	missouri: {
		header: "Missouri Nutrient Reduction Strategy",
		dataAndResources: [
			{
				name: "Missouri Nutrient Reduction Strategy",
				fileType: "web",
				url: "https://dnr.mo.gov/water/what-were-doing/water-planning/nutrient-loss-reduction-strategy",
			},
			{
				name: "Water Monitoring and Data",
				fileType: "dashboard",
				url: "https://dnr.mo.gov/water/hows-water/monitoring-data",
			},
			{
				name: "University of Missouri Extension Nitrogen and Phosphorus Watch",
				fileType: "web",
				url: "https://dnr.mo.gov/water/what-were-doing/initiatives/mo-nutrient-trading-program",
			},
			{
				name: "Great Lakes to Gulf - Missouri Water Quality Data Portal",
				image: MissouriState,
				url: "https://missouri.greatlakestogulf.org/",
			},
		],
	},
	ohio: {
		header: "Ohio Nutrient Reduction Strategy",
		dataAndResources: [
			{
				name: "Ohio Nutrient Reduction Strategy",
				fileType: "web",
				url: "https://epa.ohio.gov/divisions-and-offices/surface-water/reports-data/nutrient-pollution-finding-solutions",
			},
		],
	},
	tennessee: {
		header: "Tennessee Nutrient Reduction Strategy",
		dataAndResources: [
			{
				name: "Tennessee Nutrient Strategy Taskforce Triennial Report",
				fileType: "web",
				url: "https://www.tn.gov/environment/program-areas/wr-water-resources/nutrient-management-in-tennessee/partnerships/tennessee-nutrient-reduction-task-force.html",
			},
			{
				name: "Water Quality Resources",
				fileType: "dashboard",
				url: "https://www.tn.gov/environment/program-areas/wr-water-resources/tn-plant-optimization-programs/tnpop/case-studies.html",
			},
			{
				name: "Great Lakes to Gulf - Tennessee Water Quality Data Portal",
				image: TennesseeState,
				url: "https://tennessee.greatlakestogulf.org/",
			},
		],
	},
	wisconsin: {
		header: "Wisconsin Nutrient Reduction Strategy",
		dataAndResources: [
			{
				name: "Wisconsin Nutrient Reduction Strategy",
				fileType: "web",
				url: "https://dnr.wisconsin.gov/topic/SurfaceWater/NutrientStrategy.html",
			},
			{
				name: "Long-term River Water Quality Trends in Wisconsin",
				fileType: "dashboard",
				url: "https://wisconsindnr.shinyapps.io/riverwq/",
			},
			{
				name: "Wisconsin Water Monitoring",
				fileType: "web",
				url: "https://dnr.wisconsin.gov/topic/SurfaceWater/Monitoring.html",
			},
			{
				name: "Great Lakes Basin River Water-Quality Trends",
				fileType: "dashboard",
				url: "https://rconnect.usgs.gov/glritrends/",
			},
		],
	},
};
